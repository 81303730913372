var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"gutter":24}},_vm._l((_vm.stats),function(stat,index){return _c('a-col',{key:index,staticClass:"mb-24",attrs:{"span":24,"lg":12,"xl":6}},[_c('WidgetCounter',{attrs:{"title":stat.title,"value":stat.value,"prefix":stat.prefix,"suffix":stat.suffix,"icon":stat.icon,"status":stat.status}})],1)}),1),_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":12,"lg":12,"xl":24}},[_c('a-card',{staticClass:"card card-body border-0",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('h6',[_vm._v("Classement des plus gros clients")]),_c('a-form',{staticClass:"normal_login pt-2",attrs:{"id":"components-form-demo-normal-login","form":_vm.form,"hideRequiredMark":true},on:{"submit":_vm.classementSubmit}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                    'nbr',
                    {
                      initialValue: null,
                      rules: [
                        {
                          required: true,
                          message: 'Veillez entrer un nombre',
                        } ],
                    } ]),expression:"[\n                    'nbr',\n                    {\n                      initialValue: null,\n                      rules: [\n                        {\n                          required: true,\n                          message: 'Veillez entrer un nombre',\n                        },\n                      ],\n                    },\n                  ]"}],staticStyle:{"width":"300px"},attrs:{"placeholder":"Nombre de carnet","type":"number"}}),_c('a-button',{staticClass:"mx-4",attrs:{"type":"primary","htmlType":"submit"}},[_vm._v(" Envoyer ")])],1)],1),_c('a-input-search',{staticStyle:{"width":"300px"},attrs:{"placeholder":"Recherche ici"},on:{"change":_vm.onSearch},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('a-button',{staticClass:"mx-2",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Retour")])],1)]},proxy:true}])},[_c('a-table',{attrs:{"columns":_vm.columns,"data-source":_vm.data,"pagination":true},scopedSlots:_vm._u([{key:"operation",fn:function(text, record){return [_c('router-link',{attrs:{"to":{ name: 'Client_detail', params: { id: record.key } }}},[_c('a-button',{attrs:{"type":"primary","size":"small"}},[_vm._v("Détail")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }